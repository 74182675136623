export default class Menu {
  constructor(el) {
    this.DOM = { el: el };
    this.DOM.trigger = document.querySelector('.header__toggle');
    this.state = {};
    this.state.isOpen = false;

    this.toggle = this.toggle.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.init();
  }

  init() {
    if (!this.DOM.el) return;
    setTimeout(() => this.DOM.el.style.display = 'flex', 500);
    this.bindEvents();
  }

  bindEvents() {
    this.DOM.trigger.addEventListener('click', this.toggle);
  }

  toggle() {
    this.state.isOpen ? this.close() : this.open();
  }

  open() {
    this.state.isOpen = true;
    this.DOM.trigger.classList.add('is-active');
    this.DOM.el.classList.add('is-visible');
  }

  close() {
    this.state.isOpen = false;
    this.DOM.trigger.classList.remove('is-active');
    this.DOM.el.classList.remove('is-visible');
  }
}