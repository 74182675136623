import '../styles/main.scss';
import smoothscroll from 'smoothscroll-polyfill';
import Util from './modules/Utilities';
import Swiper from './vendors/swiper.min.js';
import Menu from './modules/Menu';

document.addEventListener('DOMContentLoaded', () => {
  smoothscroll.polyfill();
  Util.forEachPolyfill();
  Util.anchorsInit();

  new Menu(document.querySelector('.menu'));

  new Swiper('.hero__container', {
    slidesPerView: 1,
    loop: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    speed: 1000,
    pagination: {
      el: '.swiper-pagination',
      type: 'custom',
      renderCustom: (swiper, current, total) => {
          return current + ' / ' + total;
      }
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
  });
});

window.onload = () => {
  const lazyImages = document.querySelectorAll('[data-lazy-src]');

  lazyImages.forEach(image => {
    let source = image.getAttribute('data-lazy-src');
    image.style.backgroundImage = `url(${source})`;
  });
}